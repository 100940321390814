













































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import useAttachment from "@/use/attachment";
import useService from "@/use/service";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
    OAttachments: () => import("@/components/organisms/o-attachments.vue"),
  },
  setup(_, { root }) {
    const axiosInstance = root?.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;
    const {
      fileTypes,
      fileTypesString,
      truncateFileName,
      uploadAttachments,
      downloadAttachment,
      deleteAttachment,
    } = useAttachment({ root });
    const { formatService, serviceData } = useService();

    const form = ref<any>(null);

    const services = ref<any[]>([]);

    const state = reactive({
      dialog: false,
      loading: false,
      headers: [
        { value: "date", text: "Data", sortable: false },
        { value: "type", text: "Typ", sortable: false },
        { value: "cost", text: "Koszt", sortable: false },
        { value: "mileage", text: "Przebieg", sortable: false },
        {
          value: "nextServiceDate",
          text: "Data następnego serwisu",
          sortable: false,
        },
        {
          value: "nextServiceMileage",
          text: "Przebieg następnego serwisu",
          sortable: false,
        },
        {
          value: "data-table-expand",
          text: "",
          sortable: false,
          align: "end",
          width: 1,
        },
      ],
      items: computed(() =>
        services.value.map((service) => ({
          id: service.id,
          date: service.date,
          type: service.type,
          tireType: service.tireType,
          cost: service.cost,
          mileage: service.mileage,
          description: service.description,
          nextServiceDate: service.nextServiceDate,
          nextServiceMileage: service.nextServiceMileage,
          tireStoragePlace: service.tireStoragePlace,
          tireSize: service.tireSize,
          attachments: service.attachments,
        }))
      ),
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      total: 0,
      expanded: [],
      uploading: [] as { id: string; file: File }[],
      menu: {
        date: false,
        nextServiceDate: false,
      },
      isFormValid: true,
    });

    const model = reactive<{
      type: null | string;
      tireType: null | string;
      cost: null | number;
      date: null | string;
      mileage: null | number;
      nextServiceDate: null | string;
      nextServiceMileage: null | number;
      description: null | string;
      tireStoragePlace: null | string;
      tireSize: null | string;
      attachments: File[];
    }>({
      type: null,
      tireType: null,
      cost: null,
      date: null,
      mileage: null,
      nextServiceDate: null,
      nextServiceMileage: null,
      description: null,
      tireStoragePlace: null,
      tireSize: null,
      attachments: [],
    });

    const rules = {
      type: [(v: string) => !!v || "Wybierz typ serwisu"],
      tireType: [(v: string) => !!v || "Wybierz rodzaj opon"],
      date: [(v: string) => !!v || "Podaj datę wykonania serwisu"],
      nextServiceDate: [
        (v: string) =>
          !v ||
          !model.date ||
          (model.date &&
            new Date(v).getTime() >= new Date(model.date).getTime()) ||
          "Nas†epny serwis nie może odbyć się przed poprzednim.",
      ],
      nextServiceMileage: [
        (v: number) =>
          !v ||
          !model.mileage ||
          (model.mileage && v >= model.mileage) ||
          "Przebieg nas†ępnego serwisu nie może być mniejszy niż obecny.",
      ],
      description: [
        (v: string) =>
          !v ||
          (v && v.length <= 255) ||
          "Maksymalna długość opisu to 255 znaków.",
      ],
      attachments: [
        (v: File[]) =>
          !v ||
          (v && v.length <= 5) ||
          "Maksymalna ilość plików na raz to 5. Możesz dodać więcej plików po dodaniu serwisu.",
      ],
    };

    const resetModel = () => {
      model.type = null;
      model.tireType = null;
      model.cost = null;
      model.date = null;
      model.mileage = null;
      model.nextServiceDate = null;
      model.nextServiceMileage = null;
      model.description = null;
      model.attachments = [];

      form.value.resetValidation();
    };

    const fetchServices = () => {
      const { page, itemsPerPage } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`vehicle/${root.$route.params.id}/service`, {
          params: { page, itemsPerPage },
        })
        .then(({ data }) => {
          services.value = data.services;
          state.total = data.total;
        })
        .catch(() => {
          services.value = [];
          state.total = 0;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const addService = async () => {
      const serviceData: {
        type: string;
        tireType?: string;
        cost?: number;
        date: string;
        mileage?: number;
        nextServiceDate?: string;
        nextServiceMileage?: number;
        description?: string;
        attachment?: string[];
        tireStoragePlace?: string;
        tireSize?: string;
      } = {
        type: model.type as string,
        tireType: (model.type === "tire_change" && model.tireType) || undefined,
        cost: model.cost || undefined,
        date: model.date as string,
        mileage: model.mileage || undefined,
        nextServiceDate: model.nextServiceDate || undefined,
        nextServiceMileage: model.nextServiceMileage || undefined,
        description: model.description || undefined,
        tireStoragePlace:
          (model.type === "tire_change" && model.tireStoragePlace) || undefined,
        tireSize: (model.type === "tire_change" && model.tireSize) || undefined,
      };

      if (model.attachments && model.attachments.length) {
        await uploadAttachments(model.attachments)
          .then(({ data }) => {
            serviceData.attachment = data.attachments.map(
              (attachment: { id: string }) => attachment.id
            );
          })
          .catch((error) => console.log(error));
      }

      axiosInstance
        .post(`vehicle/${root.$route.params.id}/service`, serviceData)
        .then(() => {
          state.dialog = false;
          resetModel();
          fetchServices();
        })
        .catch((error) => console.log(error));
    };

    const deleteService = (id: string) => {
      state.loading = true;
      axiosInstance
        .delete(`service/${id}`)
        .then(fetchServices)
        .catch((error) => console.log(error))
        .finally(() => {
          state.loading = false;
        });
    };

    const onSubmit = async () => {
      await form.value.validate();
      if (state.isFormValid) addService();
    };

    const uploadToExisting = async (id: string, files: File[]) => {
      for (const file of files) {
        state.uploading.push({ id, file });
      }
      uploadAttachments(files).then(({ data }) => {
        axiosInstance
          .post(`service/${id}/attachment`, {
            attachment: data.attachments.map((a: { id: string }) => a.id),
          })
          .then(fetchServices)
          .finally(() => {
            for (const file of files) {
              state.uploading.splice(state.uploading.indexOf({ id, file }), 1);
            }
          });
      });
    };

    const handleFileInput = (id: string, event: Event) => {
      event.preventDefault();

      let files: File[] = [];

      const input = event.target as HTMLInputElement;
      if (input.files) {
        files = [];
        for (let i = 0; i < input.files.length; i++) {
          const file = input.files[i];
          if (file) files.push(file);
        }
      }

      if (files.length > 0) uploadToExisting(id, files);

      input.value = "";
      if (input.value) {
        input.type = "text";
        input.type = "file";
      }
    };

    const handleDrag = (id: string, event: Event, type: "enter" | "leave") => {
      event.preventDefault();

      if (type === "leave")
        (event.target as HTMLElement)?.classList.remove("hover");
      else (event.target as HTMLElement)?.classList.add("hover");
    };

    const handleDrop = (id: string, event: DragEvent) => {
      event.preventDefault();

      if (event.target)
        (event.target as HTMLElement)?.classList.remove("hover");

      let files: File[] = [];

      if (event.dataTransfer?.items) {
        // Use DataTransferItemList interface to access the file(s)
        files = [];
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (event.dataTransfer.items[i].kind === "file") {
            const file = event.dataTransfer.items[i].getAsFile();
            if (file) files.push(file);
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        files = [];
        const filesLength = event.dataTransfer?.files.length || 0;
        for (let i = 0; i < filesLength; i++) {
          const file = event.dataTransfer?.files[i] as File;
          if (file) files.push(file);
        }
      }

      if (files.length > 0) uploadToExisting(id, files);
    };

    watch(
      () => model,
      () => (form.value as any).validate(),
      { deep: true }
    );

    watch(() => state.options, fetchServices, { deep: true });
    onMounted(fetchServices);

    return {
      form,
      serviceData,
      state,
      model,
      formatService,
      rules,
      fetchServices,
      deleteService,
      onSubmit,
      handleFileInput,
      handleDrag,
      handleDrop,
      fileTypes,
      fileTypesString,
      truncateFileName,
      downloadAttachment,
      deleteAttachment,
    };
  },
});
